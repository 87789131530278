@import "colors.vars";
@import "~@angular/material/theming";

@include mat-core();

$gateway-primary: mat-palette($sd-cyan);
$gateway-accent: mat-palette($mat-orange, A200, A100, A400);

$gateway-theme: mat-light-theme((
  color: (
    primary: $gateway-primary,
    accent: $gateway-accent,
  )
));

@include angular-material-theme($gateway-theme);

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #fff;
}

@media (max-width: 495px) {
  .cdk-overlay-container {
    .cdk-overlay-pane {
      max-width: 90vw !important;
    }
  }
}

.mat-dialog-container {
  padding: 20px 15px !important;
  border: 2px solid map-get($sd-gray, 100);
  border-radius: 14px !important;

  .mat-dialog-content {
    margin: 0 -15px;
    padding: 0 15px;
  }
}
